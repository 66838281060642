import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { Grid } from '@mui/material'
import { Typography, Divider } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { navigate, useLocation, useParams } from '@reach/router'
import useModal from '_hooks/use-toggle'

import Loading from '_components/loading'
import AddProviderModal from '_components/modal/add-provider'
import {
  currentServiceOrderSelector,
  getStepStatusFilterLoadingSelector,
  getStepStatusFilterSelector,
} from '_modules/service-orders/selectors'
import {
  getButtonsAction,
  getGenericParameters,
  getServiceOrder,
  getStepStatusFilter,
  GET_SERVICE_ORDER,
  reopenServiceOrder,
  REOPEN_SERVICE_ORDER,
  VERIFY_NEW_BUDGET,
  updateServiceOrderFlowsOptions,
  UPDATE_SERVICE_ORDER,
  updateReferaService,
} from '_modules/service-orders/actions'
import { getStepStatusLog, RETURN_BUDGET_PROVIDER } from '_modules/budget/actions'
import { getHistoryLogsLoadingSelector, getEventsFilter } from '_modules/history-logs/selectors'
import { getHistoryLogs, setEventsFilter } from '_modules/history-logs/actions'
import useFetchCall from '_hooks/use-fetch-call'
import ModalDialog from '_components/modal/modal-dialog'
import ConfirmationModal from '_components/confirmation-modal'
import {
  ActionDeadline,
  ServiceOrderCanceledAlert,
  TradesmanAlertInfo,
} from '_components/service-order'
import useIsCurrentStepStatusCanceled from '_hooks/use-is-current-step-status-canceled'
import { BUTTONS_ACTION, GENERIC_PARAMETERS } from '_utils/constants/service-order'
import useIsGenericParamActive from '_hooks/use-is-generic-param-active'
import ProgressBar from '_components/service-order/progress-bar'
import AddProviderQueueModal from '_components/modal/add-provider-queue-modal'
import { REGISTER_STATUS, ROUTES, STAGE_TYPES } from '_utils/constants'

import useStyles from './styles'
import Header from './header'
import History from './history'
import Request from './steps/request'
import Estimate from './steps/estimate'
import DuplicatedTabs from './duplicated-tabs'
import { ACTIONS, INITIAL_STATE, reducer } from './reducer'
import Annotations from './annotations'
import { TAB_OPTIONS } from './annotations/constants'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useToast } from '_/hooks/use-toast'
import {
  getProviderSuggestionsList,
  getProvidersWithoutPagination,
} from '_/modules/provider/actions'
import useCanDoButtonAction from '_hooks/use-can-do-button-action'
import WarningIcon from '@mui/icons-material/Warning'
import { Alert, Loader } from '@refera/ui-web'
import AddCategoryModal from '_/components/modal/add-category-modal'
import SwitchComponent from '_/components/switch'
import { useDialog } from '_/hooks/use-dialog'
import { PoolWarning } from '_/components/PoolWarning'

const ServiceOrder = () => {
  const { serviceOrderId } = useParams()
  const dispatch = useDispatch()
  const isEditing = true

  const { showToast } = useToast()

  // TODO: Rename this constant to isServiceOrderCanceled once this status should be checked by the step_status
  const isServiceOrderCanceledByStepStatus = useIsCurrentStepStatusCanceled()
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const stepStatusFilter = useSelector(getStepStatusFilterSelector)
  const isStepStatusFilterLoading = useSelector(getStepStatusFilterLoadingSelector)
  const isHistoryLogLoading = useSelector(getHistoryLogsLoadingSelector)
  const filter = useSelector(getEventsFilter)
  const [modalOpen, handleModal] = useModal()
  const [showRSAlert, setShowRSAlert] = useState(false)
  const [isBudgetEditing, setIsBudgetEditing] = useState(false)
  const agencyContractType = useMemo(() => serviceOrder?.agency.get('contractType'), [serviceOrder])
  const { showDialog, closeDialog } = useDialog()

  const methods = useForm()
  const { getValues } = methods

  const serviceOrderCategories = useMemo(() => {
    const categories = serviceOrder?.category.toJS()
    const array = []

    categories?.forEach(item => {
      array.push(item?.id)
    })

    return array
  }, [serviceOrder])

  const handleBudgetEditing = editStatus => {
    setIsBudgetEditing(editStatus)
  }

  // const distributionActive = true
  const distributionActive = useIsGenericParamActive({
    name: GENERIC_PARAMETERS.DISTRIBUTION,
  })

  const [state, localDispatch] = useReducer(reducer, INITIAL_STATE)

  const styles = useStyles({ isEditing, step: serviceOrder?.orderStep })
  const [serviceOrderStatus, setServiceOrderStatus] = useState(STAGE_TYPES.ORDER)
  const isServiceOrderCanceled = useMemo(() => !serviceOrder?.isActive, [serviceOrder?.isActive])

  const canEditIsReferaService = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.IS_REFERA_SERVICE,
  })

  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname.endsWith('/')) navigate(pathname.slice(0, -1))
  }, [pathname])

  useEffect(() => {
    if (serviceOrder?.agency) {
      const flow = serviceOrder?.toJS().businessFront ? serviceOrder?.toJS().businessFront : {}

      const options = serviceOrder?.agency?.toJS().flows?.map(option => ({
        value: option.name,
        label: option.description,
        disabled: false,
      }))

      if (flow && !options.some(opt => opt.value === flow.name)) {
        options.push({
          value: flow.name,
          label: flow.description,
          disabled: true,
        })
      }
      options.sort((a, b) => a.label.localeCompare(b.label))
      dispatch(updateServiceOrderFlowsOptions(options))
    }
  }, [])

  const handleGetServiceOrderActive = useCallback(() => {
    dispatch(getServiceOrder(serviceOrderId)).catch(() => {
      navigate(ROUTES.LINK_EXPIRED)
    })
  }, [dispatch, serviceOrderId])

  const renderDuplicatedTabs = useMemo(
    () =>
      (serviceOrder?.serviceOrderDuplications?.size > 0 || serviceOrder?.duplicatedFrom) && (
        <DuplicatedTabs serviceOrderId={serviceOrderId} pathname={pathname} />
      ),
    [pathname, serviceOrder?.duplicatedFrom, serviceOrder?.serviceOrderDuplications, serviceOrderId]
  )

  const isDelayedActive = useIsGenericParamActive({
    name: GENERIC_PARAMETERS.DELAYED,
  })

  const isSequencingActive = useIsGenericParamActive({
    name: GENERIC_PARAMETERS.AUTOMATIC_LINKAGE_SEQUENCING,
  })

  const handleHistory = useCallback(() => {
    dispatch(getHistoryLogs(serviceOrderId, filter?.get('mainEvents')))
  }, [dispatch, serviceOrderId, filter])

  const toggleAddProviderModal = useCallback(() => {
    localDispatch({
      type: ACTIONS.TOGGLE_ADD_PROVIDER_MODAL,
    })
  }, [])

  const defaultFilters = useMemo(() => {
    const excludeOnboardingStage = [
      'homologation',
      'waiting_list',
      'suspended',
      'paused',
      'recovery',
      'closing_process',
      'registration_closed',
    ]
    return serviceOrder
      ? {
          ...(serviceOrder.getIn(['property', 'city']) && {
            cities: serviceOrder
              .getIn(['property', 'city'])
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase()
              .replaceAll(' ', '_'),
          }),
          ...(serviceOrder.category?.first?.()?.get('id') && {
            mainServices: serviceOrder.category?.first?.()?.get('id'),
          }),
          registrationStatus: REGISTER_STATUS.COMPLETE,
          excludeOnboardingStage,
        }
      : {}
  }, [serviceOrder])

  const [loading, setLoading] = useState(false)

  const sendListProviders = useCallback(async () => {
    const dataCategories = getValues('categories')
    setLoading(true)

    let updatedFilters = defaultFilters

    if (dataCategories.length) {
      // add new categories to filters
      updatedFilters = {
        ...defaultFilters,
        mainServices: [...dataCategories.map(category => category)].join(','),
      }
    }

    dispatch(getProvidersWithoutPagination(updatedFilters))
      .then(data => {
        const payload = data.reduce((acc, provider) => {
          if (serviceOrder?.tradesman !== provider.tradesmanId) {
            acc.push(provider.tradesmanId)
          }

          return acc
        }, [])

        if (!payload.length) {
          setLoading(false)
          handleModal()
          toggleAddProviderModal()

          return
        }
        handleModal()
        dispatch(
          getProviderSuggestionsList({
            providers: payload,
            serviceOrderId,
          })
        )
          .then(response => {
            setLoading(false)
            if (response?.length === 0) {
              showToast({
                type: 'error',
                message:
                  'O processo de sugestão não encontrou prestadores que possam ser automaticamente selecionados para este chamado. Tente ajustar as configurações do chamado ou realize a alocação manual.',
              })
            }
            if (modalOpen) {
              handleModal()
            }
            toggleAddProviderModal()
          })
          .catch(() => {
            showToast({
              type: 'error',
              message:
                'Ocorreu um erro no processo de sugestão de prestadores. Tente ajustar as configurações do chamado ou realize a alocação manual.',
            })
            setLoading(false)
            if (modalOpen) {
              handleModal()
            }
            toggleAddProviderModal()
          })
      })
      .catch(() => {
        setLoading(false)
        showToast({ type: 'error' })
      })
  }, [dispatch, showToast, defaultFilters, loading])

  const toggleAddProviderDistributionModal = useCallback(() => {
    if (distributionActive) {
      return handleModal()
    }

    return localDispatch({
      type: ACTIONS.TOGGLE_ADD_PROVIDER_MODAL,
    })
  }, [distributionActive])

  const toggleConfirmation = useCallback(() => {
    localDispatch({
      type: ACTIONS.TOGGLE_CONFIRMATION_MODAL,
    })
  }, [])

  const toggleTradesmanAlertInfo = useCallback(() => {
    localDispatch({
      type: ACTIONS.TOGGLE_TRADESMAN_ALERT_INFO,
    })
  }, [])

  const toggleAnnotationDrawerOpen = useCallback(() => {
    localDispatch({
      type: ACTIONS.TOGGLE_ANNOTATION_DRAWER,
      payload: {
        annotationsInitialState: {},
      },
    })
  }, [])

  const handleShowComments = useCallback(() => {
    localDispatch({
      type: ACTIONS.TOGGLE_ANNOTATION_DRAWER_ON_COMMENTS,
      payload: {
        annotationsInitialState: {
          currentTab: TAB_OPTIONS.COMMENTS,
          currentButton: TAB_OPTIONS.COMMENTS.defaultButton,
        },
      },
    })
  }, [])

  const handleShowHelpRequest = useCallback(() => {
    localDispatch({
      type: ACTIONS.UPDATE_STATE,
      payload: {
        annotationsInitialState: {
          currentTab: TAB_OPTIONS.HELP_REQUEST,
          currentButton: TAB_OPTIONS.HELP_REQUEST.defaultButton,
        },
      },
    })
    toggleAnnotationDrawerOpen()
  }, [])

  const updateNewBudgetRejectedModal = useCallback(params => {
    localDispatch({
      type: ACTIONS.UPDATE_NEW_BUDGET_REJECTED_MODAL,
      payload: {
        ...params,
      },
    })
  }, [])

  const onNewBudgetRejected = useCallback(
    response => {
      updateNewBudgetRejectedModal({
        isOpen: true,
        subTitle: response.first(),
      })
    },
    [updateNewBudgetRejectedModal]
  )

  const hasSpecialClassification = useCallback(
    name => {
      const classifications = serviceOrder?.specialClassifications?.toJS()
      if (name === 'Serviço RS')
        return classifications?.some(classification => classification.classificationName === name)

      return classifications?.some(classification => classification.name === name)
    },
    [serviceOrder]
  )

  useEffect(() => {
    if (hasSpecialClassification('Serviço RS')) setShowRSAlert(true)
  }, [hasSpecialClassification, setShowRSAlert])

  const handleSpecialAlert = useMemo(() => {
    if (hasSpecialClassification('contact_refera_forbidden')) {
      return {
        message: 'ATENÇÃO: Cliente que a Refera não pode contatar por solicitação da imobiliária',
        color: 'black',
        fontColor: 'white',
      }
    }
    if (hasSpecialClassification('vip_client')) {
      return { message: 'ATENÇÃO: Chamado de um cliente VIP', fontColor: 'black' }
    }
    return null
  }, [hasSpecialClassification])

  const onNewBudgetSuccess = useCallback(() => {
    if (isServiceOrderCanceled) {
      toggleConfirmation()
    } else {
      toggleAddProviderDistributionModal()
    }
  }, [isServiceOrderCanceled, toggleAddProviderDistributionModal])

  const handleSuccessReopenServiceOrderAction = useCallback(() => {
    dispatch(getServiceOrder(serviceOrderId)).catch(() => {
      navigate(ROUTES.LINK_EXPIRED)
    })
    toggleAddProviderModal()
    toggleConfirmation()
  }, [dispatch, toggleAddProviderModal, toggleConfirmation, serviceOrderId])

  useFetchCall(REOPEN_SERVICE_ORDER.ACTION, handleSuccessReopenServiceOrderAction)
  const [isUpdatingServiceOrder] = useFetchCall(UPDATE_SERVICE_ORDER.ACTION)

  const handleAddProvider = useCallback(() => {
    dispatch(reopenServiceOrder(serviceOrderId))
  }, [dispatch, serviceOrderId])

  useFetchCall(VERIFY_NEW_BUDGET.ACTION, onNewBudgetSuccess, onNewBudgetRejected)

  const onCloseModal = useCallback(
    updatedProvider => {
      if (updatedProvider === 'updatedProvider') {
        handleHistory()
      }
      if (modalOpen) {
        handleModal()
      }
      toggleAddProviderModal()
    },
    [handleHistory, toggleAddProviderModal]
  )

  const onGetServiceOrderSuccess = useCallback(() => {
    if (serviceOrder?.tradesman) {
      setServiceOrderStatus(serviceOrder?.stage)
    }
  }, [serviceOrder?.stage, serviceOrder?.tradesman])

  // TODO: Remove this dispatch once the BE is sending the step_status info when updating the budget
  const refetchServiceOrder = useCallback(() => {
    dispatch(getServiceOrder(serviceOrderId)).catch(() => {
      navigate(ROUTES.LINK_EXPIRED)
    })
  }, [dispatch, serviceOrderId])

  useFetchCall(GET_SERVICE_ORDER.ACTION, onGetServiceOrderSuccess)
  useFetchCall(RETURN_BUDGET_PROVIDER.ACTION, refetchServiceOrder)

  useEffect(() => {
    if (serviceOrderId && serviceOrder === undefined) {
      dispatch(getServiceOrder(serviceOrderId)).catch(() => {
        navigate(ROUTES.LINK_EXPIRED)
      })
      dispatch(setEventsFilter({ name: 'mainEvents', value: true }))
    }
    dispatch(getHistoryLogs(serviceOrderId, filter?.get('mainEvents')))
  }, [serviceOrderId, filter])

  useEffect(() => {
    setLoading(!!isUpdatingServiceOrder)
  }, [isUpdatingServiceOrder])

  useEffect(() => {
    if (!stepStatusFilter && !isStepStatusFilterLoading) {
      dispatch(getStepStatusFilter())
    }
  }, [dispatch, stepStatusFilter, isStepStatusFilterLoading])

  useEffect(() => {
    if (serviceOrderId) {
      Promise.resolve(dispatch(getStepStatusLog(serviceOrderId))).then(() => {
        dispatch(getButtonsAction())
      })
    }
  }, [serviceOrderId])

  useEffect(() => {
    // dispatch(getButtonsAction())
    dispatch(
      getGenericParameters({
        name: GENERIC_PARAMETERS.DELAYED,
      })
    )
  }, [dispatch])

  useEffect(() => {
    if (serviceOrder?.get('agency')?.get('id')) {
      dispatch(
        getGenericParameters({
          agencyId: serviceOrder.get('agency').get('id'),
          name: GENERIC_PARAMETERS.STEP_STATUS_STRUCTURE,
        })
      )
      dispatch(
        getGenericParameters({
          name: GENERIC_PARAMETERS.PAUSE_SERVICE_ORDER,
        })
      )
      dispatch(
        getGenericParameters({
          name: GENERIC_PARAMETERS.AUTOMATIC_LINKAGE_SEQUENCING,
        })
      )
      dispatch(
        getGenericParameters({
          name: GENERIC_PARAMETERS.DISTRIBUTION,
        })
      )
    }
  }, [dispatch, serviceOrder?.get('agency')?.get('id')])

  useEffect(() => {
    if (serviceOrder?.tradesman) {
      setServiceOrderStatus(STAGE_TYPES.BUDGET)
    }
  }, [dispatch, serviceOrder?.tradesman])

  useEffect(() => {
    methods.register('categories')
  }, [])

  const handleIsReferaServiceChange = useCallback(
    async (newValue, successCallBack = () => {}) => {
      setLoading(true)
      dispatch(updateReferaService(serviceOrderId, { isReferaService: newValue }))
        .then(() => {
          setLoading(false)
          showToast({
            type: 'success',
          })
          successCallBack()
        })
        .catch(error => {
          const errorMessage = error?.response?.data?.error_message
          showToast({
            type: 'error',
            message: errorMessage,
          })
          setLoading(false)
        })
    },
    [serviceOrderId]
  )

  const onIsReferaServiceChange = useCallback(
    (event, componentOnChange) => {
      const isReferaServicePreviousValue = getValues('isReferaService')
      const isReferaServiceNextValue = event.target.checked

      // atualiza e retorna
      if (isReferaServicePreviousValue)
        handleIsReferaServiceChange(isReferaServiceNextValue, () =>
          componentOnChange(isReferaServiceNextValue)
        )
      // pergunta se quer direcionar para refera e se sim, atualiza e retorna
      else
        showDialog({
          type: 'info',
          subject:
            'Você tem certeza que deseja direcionar este chamado para ser atendido pela Refera?',
          labelApprove: 'Sim',
          labelCancel: 'Não',
          onApprove: () =>
            handleIsReferaServiceChange(isReferaServiceNextValue, () => {
              componentOnChange(isReferaServiceNextValue)
              closeDialog()
            }),
          onCancel: closeDialog,
        })
    },
    [methods.watch('isReferaService'), handleIsReferaServiceChange]
  )

  if (isHistoryLogLoading || !serviceOrder?.get('id')) {
    return <Loading />
  }

  return (
    <Grid className={styles.view}>
      <Loader hasBackdrop open={loading} />
      <Grid className={styles.container}>
        <Header
          isEditing={isEditing}
          isBudgetEditing={isBudgetEditing}
          handleGetServiceOrderActive={handleGetServiceOrderActive}
          handleAnnotationsButtonClick={toggleAnnotationDrawerOpen}
          handleShowComments={handleShowComments}
          setLoading={setLoading}
        />
        {renderDuplicatedTabs}
        {!pathname.includes('/orcamento') && (
          <>
            <TradesmanAlertInfo
              open={state.isTradesmanAlertInfoOpen}
              onClose={toggleTradesmanAlertInfo}
            />

            <ServiceOrderCanceledAlert />
          </>
        )}

        {(handleSpecialAlert || showRSAlert) && (
          <Grid container gap={3} className={[styles.mainInfo, styles.alert]}>
            {handleSpecialAlert && (
              <Alert
                style={{ backgroundColor: handleSpecialAlert.color }}
                severity="warning"
                icon={<WarningIcon fontSize="medium" color="warning" />}
                title={
                  <span style={{ color: handleSpecialAlert.fontColor }}>
                    {handleSpecialAlert.message}
                  </span>
                }
              />
            )}
            {showRSAlert && (
              <Alert
                severity="warning"
                icon={<WarningIcon fontSize="medium" color="warning" />}
                title="Chamado de imóvel afetado pela enchente no Rio Grande do Sul."
                onClose={() => {
                  setShowRSAlert(false)
                }}
              />
            )}
          </Grid>
        )}

        <PoolWarning
          stepStatus={serviceOrder?.stepStatus}
          className={`${styles.mainInfo} ${styles.poolWarning}`}
        />

        {!isDelayedActive && <ProgressBar step={serviceOrderStatus} />}
        <Grid container gap={3} className={styles.mainInfo}>
          <Grid item xs={12} container gap={3}>
            {pathname.includes('/orcamento') ? (
              <Grid item xs={12}>
                <Estimate
                  handleShowHelpRequest={handleShowHelpRequest}
                  onEditChange={handleBudgetEditing}
                />
              </Grid>
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '20px',
                    marginBottom: '20px',
                    width: '100%',
                  }}
                >
                  {isDelayedActive && !isServiceOrderCanceledByStepStatus ? (
                    <ActionDeadline handleShowHelpRequest={handleShowHelpRequest} />
                  ) : null}
                  {canEditIsReferaService && agencyContractType === 'SAAS' && (
                    <div className={styles.switchInputGroup}>
                      <label className={styles.switchLabel} htmlFor="isReferaService">
                        Atendimento Refera
                      </label>
                      <Controller
                        control={methods.control}
                        name="isReferaService"
                        defaultValue={serviceOrder?.isReferaService || false}
                        render={props => (
                          <SwitchComponent
                            checked={props.value}
                            onChange={e => onIsReferaServiceChange(e, props.onChange)}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
                <Grid item xs={12}>
                  <Request />
                </Grid>
              </>
            )}
          </Grid>
          {isServiceOrderCanceled && (
            <Grid item xs={12}>
              <Divider className={styles.divider} />
              <Typography variant="h5" component="p" className={styles.descriptionTitle}>
                Chamado cancelado
              </Typography>
              <Typography color="secondary" variant="body2" component="p">
                Motivo
              </Typography>
              <Typography variant="body1" component="span">
                {serviceOrder?.reasonForCancellation?.get('reason')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <History />
      {state.isAddProviderModalOpen &&
        (isSequencingActive ? (
          <AddProviderQueueModal
            handleClose={onCloseModal}
            categories={getValues('categories')}
            isOpen={isSequencingActive}
            updateHistoryLog={handleHistory}
          />
        ) : (
          <AddProviderModal
            onCloseModal={onCloseModal}
            serviceOrder={serviceOrder}
            updateHistoryLog={handleHistory}
            isOpen={!isSequencingActive}
          />
        ))}
      {state.isConfirmationModalOpen && (
        <ConfirmationModal
          isOpen={state.isConfirmationModalOpen}
          handleClose={toggleConfirmation}
          handleConfirmClick={handleAddProvider}
          handleDeniedClick={toggleConfirmation}
          description="Este chamado está cancelado. Você deseja reativá-lo?"
          cancelButtonText="Não"
          confirmButtonText="Sim"
        />
      )}
      <ModalDialog
        modalDialog={state.newBudgetRejectedModal}
        setModalDialog={updateNewBudgetRejectedModal}
      />
      <Annotations
        handleAnnotationDrawer={toggleAnnotationDrawerOpen}
        open={state.isAnnotationDrawerOpen}
        initialState={state.annotationsInitialState}
      />

      {distributionActive && modalOpen && (
        <FormProvider {...methods}>
          <AddCategoryModal
            value={serviceOrderCategories}
            handleModal={handleModal}
            handleContinue={sendListProviders}
            patchCategories={e => methods.setValue('categories', e)}
            title="Categoria da distribuição"
            tradesmanCategories={serviceOrderCategories}
            isLoading={loading}
            leftButtonText="Continuar"
            hasFilter
          />
        </FormProvider>
      )}
    </Grid>
  )
}

export default ServiceOrder
